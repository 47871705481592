import React, { useState, useEffect } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, List, Avatar, Chip, Box, Tooltip } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useSnackbar } from 'notistack'
import InfluencerService from '../../service/InfluencerService'
import agent from '../../agent'

interface Influencer {
  influencerId: string
  first_name: string
  last_name: string
  image?: string
  conversationId?: string
}

interface Props {
  open: boolean
  onClose: () => void
  onConversationsCreated: (influencers: Influencer[]) => void
}

export const InfluencerSelectionModal: React.FC<Props> = ({ open, onClose, onConversationsCreated }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [influencers, setInfluencers] = useState<Influencer[]>([])
  const [selectedInfluencers, setSelectedInfluencers] = useState<Influencer[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredInfluencers, setFilteredInfluencers] = useState<Influencer[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchInfluencers = async () => {
      const data = await InfluencerService.getAllInfluencers({})
      setInfluencers(data)
    }
    fetchInfluencers()
  }, [])

  useEffect(() => {
    const handler = setTimeout(() => {
      const term = searchTerm.toLowerCase()
      if (term === '') {
        setFilteredInfluencers([])
      } else {
        setFilteredInfluencers(influencers.filter(inf => `${inf.first_name} ${inf.last_name}`.toLowerCase().includes(term)))
      }
    }, 500) // 1-second debounce

    return () => clearTimeout(handler)
  }, [searchTerm, influencers])

  const removeSelectedFromTheList = (id: string) => {
    setFilteredInfluencers(prev => prev.filter(inf => inf.influencerId !== id))
  }

  const handleInfluencerSelect = (influencer: Influencer) => {
    if (!selectedInfluencers.some(selected => selected.influencerId === influencer.influencerId)) {
      setSelectedInfluencers([...selectedInfluencers, influencer])
    }
  }

  const handleRemoveInfluencer = (id: string) => {
    setSelectedInfluencers(prev => prev.filter(inf => inf.influencerId !== id))
  }

  const initiateConversations = async () => {
    setLoading(true)
    const updatedInfluencers = []

    for (const inf of selectedInfluencers) {
      try {
        const conversation = await agent.Chat.createConversation({ influencerId: inf.influencerId })
        inf.conversationId = conversation.conversationId
        updatedInfluencers.push(inf)
      } catch (error) {
        enqueueSnackbar(`Konversation nicht erstellt für ${inf.first_name} ${inf.last_name}`, { variant: 'error' })
      }
    }

    setLoading(false)
    onConversationsCreated(updatedInfluencers)
    enqueueSnackbar('Konversationen erfolgreich erstellt.', { variant: 'success' })
    onClose()
    setSelectedInfluencers([])
    setFilteredInfluencers(influencers)
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose(), setSelectedInfluencers([]), setFilteredInfluencers([])
      }}
      maxWidth='md'
      fullWidth>
      <DialogTitle>Empfänger auswählen</DialogTitle>
      <DialogContent>
        <DialogContentText>Wähle die Influencer aus, an die du eine Broadcast-Nachricht senden möchten.</DialogContentText>

        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2, mb: 1 }}>
          {selectedInfluencers.map(influencer => (
            <Chip
              key={influencer.influencerId}
              avatar={<Avatar src={influencer.image} />}
              label={`${influencer.first_name} ${influencer.last_name}`}
              onDelete={() => handleRemoveInfluencer(influencer.influencerId)}
            />
          ))}
          {selectedInfluencers.length > 1 && (
            <Tooltip title='Alle ausgewählten Influencer entfernen'>
              <Chip
                onDelete={() => {
                  setSelectedInfluencers([]), setFilteredInfluencers([])
                }}
                sx={{
                  marginLeft: 0.5,
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  backgroundColor: 'transparent'
                }}
              />
            </Tooltip>
          )}
        </Box>
        <TextField label='Suche nach Influencern' fullWidth variant='outlined' margin='dense' value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />

        <List>
          {filteredInfluencers
            .filter(i => !selectedInfluencers.some(selected => selected.influencerId === i.influencerId))
            .map(inf => (
              <Button
                key={inf.influencerId}
                variant='contained'
                onClick={() => {
                  handleInfluencerSelect(inf)
                  removeSelectedFromTheList(inf.influencerId)
                }}
                sx={{ mt: 1, ml: 1 }}>
                <Avatar src={inf.image} style={{ margin: 4 }} />
                {`${inf.first_name} ${inf.last_name}`}
              </Button>
            ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose()
            setSelectedInfluencers([])
            setFilteredInfluencers([])
          }}>
          Abbrechen
        </Button>
        <LoadingButton onClick={initiateConversations} loading={loading} disabled={selectedInfluencers.length === 0}>
          Weiter
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
