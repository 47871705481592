import { 
  Button, Dialog, DialogActions, DialogContent, DialogContentText, 
  DialogTitle, TextField, List, ListItem, ListItemAvatar, Avatar, 
  ListItemText 
} from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { LoadingButton } from '@mui/lab'
import { useChat } from '../../context/ChatContext'
import agent from '../../agent'
import InfluencerService from '../../service/InfluencerService'

// Define the Influencer interface
interface Influencer {
  isActive: boolean;
  age: number;
  country: string;
  conversationId?: string;
  spoofedByAdmin?: string;
  avgUGCTime: number;
  appliedCampaigns: number;
  acceptedCampaigns: number;
  first_name: string;
  last_name: string;
  influencerId: string;
  image?: string;
  userId: string;
}

export const CreateConversationView = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { influencerId: influencerIdFromPath } = useParams<{ influencerId?: string }>()
  const { conversations, setConversations, setSelectedConvo } = useChat()
  const [influencerId, setInfluencerId] = useState('')
  const [influencers, setInfluencers] = useState<Influencer[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredInfluencers, setFilteredInfluencers] = useState<Influencer[]>([])
  const [loading, setLoading] = useState(false)
  const [selectedInfluencer, setSelectedInfluencer] = useState('')
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const fetchInfluencers = async () => {
      const influencers = await InfluencerService.getAllInfluencers({})
      setInfluencers(influencers)
      setFilteredInfluencers(influencers)  // Initialize with all influencers
    }

    fetchInfluencers()
  }, [])

  // Filter influencers based on search term
  useEffect(() => {
    if (searchTerm) {
      const searchTermLower = searchTerm.toLowerCase().trim();
      
      setFilteredInfluencers(
        influencers.filter(influencer => {
          const firstName = influencer?.first_name?.toLowerCase() || '';
          const lastName = influencer?.last_name?.toLowerCase() || '';
          const fullName = `${firstName} ${lastName}`.trim();
          
          return fullName.includes(searchTermLower);
        })
      );
    } else {
      setFilteredInfluencers([]);
    }
  }, [searchTerm, influencers]);
  
  

  const createConversation = async () => {
    setLoading(true)
    try {
      const existingConvo = conversations.find(c => c.influencerId === influencerId)
      if (existingConvo) {
        setSelectedConvo(existingConvo)
      } else {
        const newConvo = await agent.Chat.createConversation({ influencerId })
        setConversations([...conversations, newConvo])
        setSelectedConvo(newConvo)
        enqueueSnackbar('Created conversation', { variant: 'success' })
      }
      setOpen(false)
    } catch (e) {
      enqueueSnackbar('Failed to create conversation', { variant: 'error' })
    }
    setLoading(false)
  }

  useEffect(() => {
    if (influencerIdFromPath) {
      createConversation()
      setOpen(true)
    }
  }, [influencerIdFromPath])

  return (
    <>
      <Dialog open={open} onClose={() => {setOpen(false), setSearchTerm('')}}>
        <DialogTitle>Open conversation</DialogTitle>
        <DialogContent>
          <DialogContentText>{`Search for an influencer to start a conversation`}</DialogContentText>
          <TextField
            autoFocus
            margin='dense'
            label='Search by name or surname'
            type='text'
            fullWidth
            variant='standard'
            value={searchTerm || selectedInfluencer }
            onChange={e => {setSearchTerm(e.target.value); setSelectedInfluencer('')}}
          />
          <List>
            {filteredInfluencers.map((influencer) => (
              <Button 
               variant='contained'
                key={influencer.influencerId}
                onClick={() => {setInfluencerId(influencer.influencerId); setSelectedInfluencer(influencer.first_name + ' '+ influencer.last_name); setSearchTerm('')} }
                sx={{ backgroundColor: 'primary.main' , marginTop: 1, paddingVertical: 1, marginRight:1}}
              >
                <ListItemAvatar>
                  <Avatar src={influencer.image} alt={influencer.first_name} />
                </ListItemAvatar>
                <ListItemText primary={influencer.first_name + ' ' + influencer.last_name} />
              </Button>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton 
            variant='contained' 
            onClick={createConversation} 
            loading={loading} 
            disabled={!influencerId}
          >
            Open conversation
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Button variant='contained' onClick={() => setOpen(true)}>
        Open conversation
      </Button>
    </>
  )
}
