import { LoadingButton } from '@mui/lab'
import { Grid, TextField } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import noPic from '../../../assets/illustrations/im_no-participants.png'
import influencerService from '../../../service/InfluencerService'
import styles from '../../../style/influencers.module.css'

export type Influencer = Awaited<ReturnType<typeof influencerService.getInfluencer>>

const Credit = () => {
  const [loading, setLoading] = useState(false)
  const [influencer, setInfluencer] = useState<Influencer>()
  const [searchField, setSearchField] = useState('')
  const [reason, setReason] = useState('')
  const [amount, setAmount] = useState(0)

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const loadInfluencer = async () => {
    setLoading(true)

    try {
      const influencer = await influencerService.getInfluencer({ influencerId: searchField })
      setInfluencer(influencer)
    } catch (e) {
      enqueueSnackbar(`Fehler beim Laden`, { variant: 'error' })
      setInfluencer(undefined)
    } finally {
      setLoading(false)
    }
  }

  const uploadCredit = async () => {
    if (!influencer) return
    const key = enqueueSnackbar('Credit Aufladen lädt', { variant: 'info', autoHideDuration: null })
    try {
      const { influencerId } = influencer
      await influencerService.uploadCredit({ influencerId, reason, amount: amount * 100 })

      enqueueSnackbar('Credit Aufladen hat funktioniert', { variant: 'success' })
      setInfluencer(undefined)
    } catch (e) {
      console.error(e)
      enqueueSnackbar('Fehler beim Credit Aufladen', { variant: 'error' })
    } finally {
      closeSnackbar(key)
    }
  }

  const renderSearchBar = () => {
    return (
      <Grid container item xs={12} p={2}>
        <Grid item xs={12} md={6}>
          <TextField
            value={searchField}
            label='Suche'
            onKeyPress={ev => ev.key == 'Enter' && loadInfluencer()}
            onChange={e => setSearchField(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4} pl={2}>
          <LoadingButton variant='contained' loading={loading} onClick={loadInfluencer}>
            Suchen
          </LoadingButton>
        </Grid>
      </Grid>
    )
  }

  const renderReasonAmount = () => {
    return (
      <Grid container item xs={12} p={2}>
        <Grid item xs={12} md={6}>
          <TextField value={reason} label='Grund für den Credit' onChange={e => setReason(e.target.value)} fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField value={amount} label='Höhe des Credits in €' onChange={e => setAmount(+e.target.value)} fullWidth />
        </Grid>
        <Grid item xs={12} md={4} pl={2}>
          <LoadingButton variant='contained' loading={loading} onClick={uploadCredit}>
            Aufladen
          </LoadingButton>
        </Grid>
      </Grid>
    )
  }
  return (
    <Grid container>
      {renderSearchBar()}
      {influencer && renderReasonAmount()}
      {influencer && (
        <Grid item xs={12} xl={6} style={{ padding: '32px' }} id={influencer.influencerId}>
          <Grid container className={styles.influencerCard} alignItems='center'>
            <img src={influencer.stylinkData.image_urls?.standard?.url || noPic} className={styles.profilePic} alt='Pic' />
            <div className={styles.infoBox}>
              <Grid container style={{ padding: '16px' }}>
                <Grid item xs={12} style={{ fontSize: '20px' }}>
                  <Grid container alignItems='center' style={{ height: '100%' }}>
                    <b
                      onClick={() => {
                        navigator.clipboard.writeText(influencer.influencerId)
                      }}>
                      {influencer.stylinkData.payment.first_name} {influencer.stylinkData.payment.last_name}
                    </b>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default Credit
