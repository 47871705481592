import React, { useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextareaAutosize, Chip, Avatar, Box, IconButton } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useSnackbar } from 'notistack'
import { Influencer, MessageType } from '../../types/types'
import { useChat } from '../../context/ChatContext'
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions'
import EmojiPicker from 'emoji-picker-react'
import { Close } from '@mui/icons-material'
import { ChatPresets } from './ChatPresets'

interface Props {
  open: boolean
  onClose: () => void
  influencers: Influencer[]
}

export const BroadcastMessageModal: React.FC<Props> = ({ open, onClose, influencers }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { sendMessage, loadMessages, loadConversations } = useChat()
  const [broadcastMessage, setBroadcastMessage] = useState('')
  const [mediaFile, setMediaFile] = useState<File | null>(null)
  const [mediaType, setMediaType] = useState<'image' | 'video' | null>(null)
  const [loading, setLoading] = useState(false)
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)

  const onEmojiClick = (emojiObject: any) => {
    setBroadcastMessage(prevMessage => prevMessage + emojiObject.emoji)
  }

  const handleMediaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return

    if (file.type.startsWith('image/')) {
      setMediaType('image')
      setMediaFile(file)
    } else {
      enqueueSnackbar('Nur Bild ist erlaubt.', { variant: 'error' })
    }
  }

  const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = error => reject(error)
    })
  }

  const handleSendMessage = async () => {
    setLoading(true)
    let successCount = 0
    const mediaContent = mediaFile ? await fileToBase64(mediaFile) : null
    if (!influencers) return

    await Promise.all(
      influencers.map(async inf => {
        if (!inf.conversationId) return
        try {
          const promises = []

          // Send text message if there’s text
          if (broadcastMessage) {
            promises.push(sendMessage(inf.conversationId, broadcastMessage, MessageType.text))
          }

          // Send media message if there's a media file
          if (mediaFile && mediaContent) {
            promises.push(sendMessage(inf.conversationId, mediaContent, mediaType === 'image' ? MessageType.photo : MessageType.video))
          }

          // Await all promises for the current influencer
          await Promise.all(promises)
          successCount++
        } catch (error) {
          enqueueSnackbar(`Senden an ${inf.influencerId} fehlgeschlagen`, { variant: 'error' })
        }
      })
    )

    enqueueSnackbar(`Nachricht an ${successCount} Empfänger gesendet.`, { variant: 'success' })
    setLoading(false)
    onClose()
    loadMessages()
    loadConversations()
    setBroadcastMessage('')
    setMediaFile(null)
    setMediaType(null)
    setShowEmojiPicker(false)
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
      <DialogTitle>Compose Broadcast Message</DialogTitle>
      <DialogContent>
        <Box sx={{ marginBottom: 2 }}>
          <p>Sie senden die Broadcast-Nachricht an die unten aufgeführten Empfänger:</p>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 1,
            marginBottom: 2,
            padding: 2,
            backgroundColor: '#f4f4f4',
            borderRadius: '8px'
          }}>
          {influencers.map(inf => (
            <Chip
              key={inf.influencerId}
              avatar={<Avatar src={inf.image} />}
              label={`${inf.first_name} ${inf.last_name}`}
              sx={{
                backgroundColor: '#a890fe',
                color: '#fff',
                padding: '4px 10px',
                borderRadius: '16px',
                fontWeight: 'bold'
              }}
            />
          ))}
        </Box>

        {/* Text input for broadcast message */}
        <TextareaAutosize
          minRows={4}
          placeholder='Geben Sie hier Ihre Nachricht ein...'
          style={{
            width: '100%',
            padding: '8px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            marginTop: '16px'
          }}
          value={broadcastMessage}
          onChange={e => setBroadcastMessage(e.target.value)}
          onFocus={() => setShowEmojiPicker(false)}
        />

        {/* File upload and emoji picker */}
        <ChatPresets
            onSelectPreset={(content) => setBroadcastMessage(content)}
          />
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginTop: -1 }}>
          <Button component='label'>
            {!mediaFile ? 'Foto anhängen' : 'Bild ändern'}
            <input hidden accept='image/*' type='file' onChange={handleMediaChange} />
          </Button>
          <IconButton onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
            <EmojiEmotionsIcon />
          </IconButton>
        </Box>

        {/* Emoji picker display */}
        {showEmojiPicker && (
          <Box sx={{ position: 'relative', zIndex: 1000, boxShadow: 3, width: '50%' }}>
            <EmojiPicker onEmojiClick={onEmojiClick} style={{ width: '100%' }} height={400} />
          </Box>
        )}
         

        {/* Preview media file if selected */}
        {mediaFile && (
          <Box sx={{ mt: 2, display: 'flex', position: 'relative' }}>
            {mediaType === 'image' && <img src={URL.createObjectURL(mediaFile)} alt='Selected media' style={{ maxWidth: '30%', borderRadius: 10 }} />}
            <IconButton
              onClick={() => {
                setMediaFile(null)
                setMediaType(null)
              }}
              sx={{
                position: 'relative',
                top: 10,
                right: 48,
                color: 'white',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.7)'
                }
              }}>
              <Close />
            </IconButton>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose()
            setBroadcastMessage('')
            setMediaFile(null)
            setMediaType(null)
            setShowEmojiPicker(false)
          }}>
          Abbrechen
        </Button>
        <LoadingButton onClick={handleSendMessage} loading={loading} disabled={!broadcastMessage && !mediaFile}>
          Broadcast senden
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
