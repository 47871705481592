import { LoadingButton } from "@mui/lab"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextareaAutosize, TextField } from "@mui/material"
import EmojiPicker from "emoji-picker-react"
import React, { useState } from "react"
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions'

interface PresetSaveDialogProps {
    open: boolean
    loading: boolean
    onClose: () => void
    onSave: (title: string, content: string) => Promise<void>
  }
  
  export const PresetSaveDialog: React.FC<PresetSaveDialogProps> = ({ 
    open, 
    loading, 
    onClose, 
    onSave 
  }) => {
    const [newPresetName, setNewPresetName] = useState('')
    const [currentPreset, setCurrentPreset] = useState('')
    const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  
    const handleEmojiClick = (emojiObject: any) => {
        setCurrentPreset(prevMessage => prevMessage + emojiObject.emoji)
      }
  
    const handleSave = async () => {
      if (!currentPreset.trim()) return
  
      await onSave(newPresetName.trim(), currentPreset)
      
      // Reset state
      setNewPresetName('')
      setCurrentPreset('')
    }
  
    return (
      <Dialog open={open} onClose={onClose} maxWidth='xs' fullWidth>
        <DialogTitle>Als Vorlage speichern</DialogTitle>
        <DialogContent>
          <TextField 
            label='Vorlage Titel' 
            fullWidth 
            value={newPresetName} 
            onChange={e => setNewPresetName(e.target.value)} 
            sx={{ mb: 2 }} 
          />
          <Box sx={{ position: 'relative' }}>
            <TextareaAutosize
              minRows={4}
              placeholder='Nachrichtenvorlage hinzufügen'
              style={{
                width: '100%',
                padding: '8px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                marginTop: '16px'
              }}
              value={currentPreset}
              onChange={e => setCurrentPreset(e.target.value)}
              onFocus={() => setShowEmojiPicker(false)}
            />
            <IconButton 
              onClick={() => setShowEmojiPicker(!showEmojiPicker)} 
              sx={{ position: 'relative' }}
            >
              <EmojiEmotionsIcon />
            </IconButton>
          </Box>
  
          {showEmojiPicker && (
            <Box
              sx={{
                position: 'relative',
                zIndex: 1000,
                right: 0,
                marginTop: 1
              }}
            >
              <EmojiPicker onEmojiClick={handleEmojiClick} />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onClose()
              setCurrentPreset('')
            }}
            disabled={loading}
          >
            Abbrechen
          </Button>
          <LoadingButton
            onClick={handleSave}
            variant='contained'
            loading={loading}
            disabled={loading || !currentPreset.trim()}
          >
            Speichern
          </LoadingButton>
        </DialogActions>
      </Dialog>
    )
  }
  