import React, { useState } from "react"

import { Dialog, DialogContent, DialogTitle, IconButton, Stack, TextField } from "@mui/material"
import AddIcon from '@mui/icons-material/Add'
import { LoadingButton } from "@mui/lab"
import DeleteIcon from '@mui/icons-material/Delete'
import { useImmer } from "use-immer"
import { State } from "../../util"



 export const DenyDialog = ({ open, setOpen, deny }: State<'open', boolean> & { deny(_: string[]): Promise<void> }) => {
    const [reasons, setReasons] = useImmer([''])
    const [loading, setLoading] = useState(false)
  
    const manage = async () => {
      setLoading(true)
      try {
        await deny(reasons)
        setOpen(false)
      } finally {
        setLoading(true)
      }
    }
  
    return (
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Content deny reasons</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            {reasons.map((r, i) => (
              <Stack key={i} direction='row' alignItems='center'>
                <TextField
                  value={r}
                  onChange={e => {
                    const value = e.currentTarget?.value || ''
                    setReasons(p => {
                      p[i] = value
                    })
                  }}
                />
                <IconButton
                  onClick={() =>
                    setReasons(p => {
                      p.splice(i, 1)
                    })
                  }>
                  <DeleteIcon />
                </IconButton>
              </Stack>
            ))}
            <IconButton
              onClick={() =>
                setReasons(p => {
                  p.push('')
                })
              }>
              <AddIcon />
            </IconButton>
          </Stack>
          <LoadingButton variant='contained' loading={loading} onClick={manage}>
            Deny
          </LoadingButton>
        </DialogContent>
      </Dialog>
    )
  }