import React, { useState } from 'react'
import { Button } from '@mui/material'
import { InfluencerSelectionModal } from './InfluencerSelectionModal'
import { BroadcastMessageModal } from './BroadcastMessageModal'

export const CreateBroadcast: React.FC = () => {
  const [isInfluencerModalOpen, setInfluencerModalOpen] = useState(false)
  const [isBroadcastModalOpen, setBroadcastModalOpen] = useState(false)
  const [selectedInfluencers, setSelectedInfluencers] = useState([])

  const handleOpenInfluencerModal = () => {
    setInfluencerModalOpen(true)
  }

  const handleConversationsCreated = (influencers: any) => {
    setSelectedInfluencers(influencers)
    setInfluencerModalOpen(false)
    setBroadcastModalOpen(true)
  }
  return (
    <>
      <Button variant='contained' onClick={handleOpenInfluencerModal} sx={{ mb: 2 }}>
        Broadcast erstellen
      </Button>

      <InfluencerSelectionModal open={isInfluencerModalOpen} onClose={() => setInfluencerModalOpen(false)} onConversationsCreated={handleConversationsCreated} />

      <BroadcastMessageModal open={isBroadcastModalOpen} onClose={() => setBroadcastModalOpen(false)} influencers={selectedInfluencers} />
    </>
  )
}
