import * as Icons from '@mui/icons-material'
import { Button, Collapse, IconButton, List, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { MessageField } from '../chat/MessageField'
import { MessagesView } from '../chat/MessagesView'
import { PresetList } from '../chat/PresetList'
import { usePresets } from '../chat/UsePresets'
import { PresetSaveDialog } from '../chat/PresetSaveDialog'
import Add from '@mui/icons-material/Add'

export const MessageFieldWithPresets = () => {
  const [showPresets, setShowPresets] = useState(false)
  const [message, setMessage] = useState('')
  const { presets, loading, savePreset, deletePreset, fetchPresets } = usePresets()
  const [showSaveDialog, setShowSaveDialog] = useState(false)

  const handleSelectPreset = (content: string) => {
    setMessage(content)
  }
  const handleSavePreset = async (title: string, content: string) => {
    const success = await savePreset({
      content,
      title,
      presetId: ''
    })

    if (success) {
      setShowSaveDialog(false)
    }
  }

  const handleDeletePreset = async (presetId: string) => {
    await deletePreset(presetId)
  }

  return (
    <Box bgcolor='white'>
      <Button
        size='small'
        onClick={() => {
          setShowPresets(v => !v)
          fetchPresets()
        }}>
        Show Presets
      </Button>
      <Tooltip title='Vorlage hinzufügen'>
        <IconButton onClick={() => setShowSaveDialog(true)} size='small' disabled={loading}>
          <Add color='action' />
        </IconButton>
      </Tooltip>
      <Collapse in={showPresets} timeout='auto' unmountOnExit sx={{ marginLeft: 1 }}>
        <List component='div' disablePadding>
          <PresetList presets={presets} loading={loading} onSelectPreset={handleSelectPreset} onDeletePreset={handleDeletePreset} />
        </List>
      </Collapse>
      <PresetSaveDialog open={showSaveDialog} loading={loading} onClose={() => setShowSaveDialog(false)} onSave={handleSavePreset} />
      <MessageField message={message} setMessage={setMessage} />
    </Box>
  )
}

const ChatView = () => (
  <>
    <MessagesView height='490px' />
    <MessageFieldWithPresets />
  </>
)

export default ChatView
