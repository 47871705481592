import CommentIcon from '@mui/icons-material/Comment'
import SendIcon from '@mui/icons-material/Send'
import { Chip, CircularProgress, IconButton, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { GridRowHeightParams } from '@mui/x-data-grid'
import { GridGroupingColDefOverride, GridRenderCellParams, GridRowParams, GridRowsProp, GridValidRowModel } from '@mui/x-data-grid-premium'
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium'
import React, { useEffect, useRef, useState } from 'react'
import agent from '../../agent'
import CommentDialog from '../../components/comments/CommentDialog'
import PersistedDataGrid, { CustomGridColDef } from '../../components/dataGrid/PersistedDataGrid'
import { Locale, StatusInfo, TodoType } from '../../types/types'
import moment from 'moment'
import ImageVideoUpload from '../../components/dataGrid/ImageVideoUpload'
import CheckIcon from '@mui/icons-material/Check'
import CloudOffIcon from '@mui/icons-material/CloudOff'
import campaignService from '../../service/CampaignService'
import { closeSnackbar, enqueueSnackbar } from 'notistack'
import * as Icons from '@mui/icons-material'
import { getStatusInfoColor, translateStatusInfo } from '../../util'

export type DashboardData = Awaited<ReturnType<typeof agent.Todo.getDashboard>>
export type Todo = Awaited<ReturnType<typeof agent.Todo.getDashboard>>[number]['campaigns'][number]['campaignTimeline'][number]

const EndDateButton = ({ row, callback }: { row: GridValidRowModel; callback: (newEndDate: number) => void }) => {
  const [newEndDate, setNewEndDate] = useState<number>(+row.endDate)
  const [settingEndDate, setSettingEndDate] = useState(false)
  const pickerRef = useRef<null | HTMLInputElement>(null)

  const onDateChange = async (newEndDate: number) => {
    try {
      setSettingEndDate(true)
      await campaignService.setTodoEndDate({ campaignId: row.campaignId, influencerId: row.influencerId, todoId: row.todoId, endDate: newEndDate })
      setNewEndDate(newEndDate)
      callback(newEndDate)
    } catch (e) {
      enqueueSnackbar(`Fehler: ${e}`, { variant: 'error' })
    } finally {
      setSettingEndDate(false)
    }
  }

  if (settingEndDate) return <CircularProgress />

  return (
    <Stack alignItems='center' width='70px'>
      <TextField
        sx={{ visibility: 'hidden', width: 0, height: 0 }}
        type='date'
        value={moment(newEndDate).format('YYYY-MM-DD')}
        onChange={e => onDateChange(moment(e.target.value, 'YYYY-MM-DD').valueOf())}
        InputLabelProps={{ shrink: true }}
        InputProps={{ ref: pickerRef }}
        size='small'
        inputProps={{ style: { fontSize: 12 } }}
      />
      {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        <IconButton onClick={() => (pickerRef.current?.childNodes.item(0) as any)?.showPicker()}>
          <Icons.Timer sx={{ color: row.oldEndDates?.at(-1) ? 'red' : undefined }} />
        </IconButton>
      }
      <Typography component='div' fontSize={11}>
        {(row.oldEndDates?.at(-1) && moment(row.oldEndDates?.at(-1)).format('DD.MM.')) ?? ''}
      </Typography>
    </Stack>
  )
}

const Dashboard = () => {
  const [rows, setRows] = useState<GridRowsProp | undefined>(undefined)
  const [commentRow, setCommentRow] = useState<GridValidRowModel | undefined>(undefined)

  useEffect(() => {
    const fetchDashboard = async () => {
      const dashboard: DashboardData = await agent.Todo.getDashboard({})

      const rowData = dashboard.flatMap(brand => {
        const { brandId, brandName, campaigns } = brand

        // Brand row (level 1 in hierarchy)
        const brandRow = {
          id: `brand-${brandId}`,
          hierarchy: [brandId], // Use brandName for hierarchy display
          brandName,
          brandId,
          campaigns
        }

        const campaignRows = campaigns.flatMap(campaign => {
          const { campaignId, title, creators, locale } = campaign

          // Campaign row (level 2 in hierarchy)
          const campaignRow = {
            id: `campaign-${brandId}-${campaignId}`,
            hierarchy: [brandId, campaignId], // Use title for the second level in hierarchy
            brandName,
            brandId,
            ...campaign
          }

          // Creator rows (level 3 in hierarchy)
          const creatorRows = creators.map(creator => {
            const creatorRow = {
              id: `creator-${brandId}-${campaignId}-${creator.influencerId}`,
              hierarchy: [brandId, campaignId, creator.influencerId],
              ...creator,
              brandName,
              title,
              campaignId,
              locale,
              brandId
            }
            return creatorRow
          })

          return [campaignRow, ...creatorRows]
        })

        return [brandRow, ...campaignRows]
      })

      setRows(rowData as GridRowsProp)
    }

    fetchDashboard()
  }, [])

  const initialState: GridInitialStatePremium = {
    // rowGrouping: {
    //   model: ['brandName', 'title']
    // },
    columns: {
      columnVisibilityModel: {
        id: false,
        brandName: false,
        title: false,
        first_name: false
      }
    }
  }

  const columns: CustomGridColDef[] = [
    { field: 'id', headerName: 'ID', width: 300 },
    {
      field: 'statusInfo',
      headerName: 'Status',
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.row.hierarchy) return ''
        if (params.row.hierarchy.length === 3)
          return <Chip label={translateStatusInfo(params.value as StatusInfo)} color={getStatusInfoColor(params.value as StatusInfo)} />
        return ''
      }
    },
    {
      field: 'campaignCreatedAt',
      headerName: 'Auftragseingang',
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.row.hierarchy) return ''

        if (params.row.hierarchy.length === 2) return moment(params.value).format('DD.MM.YYYY')
        return ''
      }
    },
    {
      field: 'campaignVerifiedAt',
      headerName: 'Auftragsfreigabe',
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.row.hierarchy) return ''
        if (params.row.hierarchy.length === 2) return moment(params.value).format('DD.MM.YYYY')
        return ''
      }
    },
    {
      field: 'creatorApplication',
      headerName: 'Bewerbung',
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params
        if (!row.hierarchy) return ''
        if (row.hierarchy.length === 2) {
          const todo: Todo = row.campaignTimeline.find((t: Todo) => t.type === TodoType.awaitFeedback)
          if (!todo) return 'Fehler'
          return `Bis ${moment(todo?.endDate).format('DD.MM.YYYY')}`
        }
        if (row.hierarchy.length === 3) {
          const todo: Todo = row.allTodos.find((t: Todo) => t.type === TodoType.awaitFeedback)
          if (!todo) return 'Fehler'
          return `Am ${moment(todo?.startDate).format('DD.MM.YYYY')}`
        }
        return ''
      }
    },
    {
      field: 'creatorAccepted',
      headerName: 'Akzeptiert',
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params
        if (!row.hierarchy) return ''
        if (row.hierarchy.length === 3) {
          if (
            row.statusInfo == StatusInfo.Applied ||
            row.statusInfo == StatusInfo.ToBeVerified ||
            row.statusInfo == StatusInfo.Rejected ||
            row.statusInfo == StatusInfo.Reported
          )
            return ''
          const todo: Todo = row.completedTodos.find((t: Todo) => t.type === TodoType.awaitFeedback)
          if (!todo) return 'Fehler'
          return `Am ${moment(todo?.completedTimestamp).format('DD.MM.YYYY')}`
        }
        return ''
      }
    },
    {
      field: 'productSent',
      headerName: 'Produkt Versand',
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params
        if (!row.hierarchy) return ''
        if (row.locale === Locale.MONEY) return ''

        if (row.hierarchy.length === 2) {
          const todo: Todo = row.campaignTimeline.find((t: Todo) => t.type === TodoType.awaitDelivery)
          if (!todo) return 'Fehler'
          return `Bis ${moment(todo?.endDate).format('DD.MM.YYYY')}`
        }
        if (row.hierarchy.length === 3) {
          if (
            row.statusInfo == StatusInfo.Applied ||
            row.statusInfo == StatusInfo.ToBeVerified ||
            row.statusInfo == StatusInfo.Rejected ||
            row.statusInfo == StatusInfo.Reported
          )
            return ''
          const todo: Todo = row.allTodos.find((t: Todo) => t.type === TodoType.awaitDelivery)
          if (!todo) return 'Fehler'
          if (!todo.isCompleted) return 'Warte auf Versand'
          return (
            <Tooltip title={`${row.shippingCode}, ${row.shippingService}`}>
              <Typography pt='10px'>Am {moment(todo?.completedTimestamp).format('DD.MM.YYYY')}</Typography>
            </Tooltip>
          )
        }
        return ''
      }
    },
    {
      field: 'firstUpload',
      headerName: 'Erster Upload',
      width: 220,
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params
        if (!row.hierarchy) return ''
        if (row.hierarchy.length === 2) {
          const uploadTodos: Todo[] = row.campaignTimeline.filter((t: Todo) => t.type === TodoType.uploadContent)
          if (!uploadTodos || !uploadTodos.length) return 'Fehler'
          const todo: Todo = uploadTodos.reduce((prev, curr) => (prev.endDate < curr.endDate ? prev : curr))
          if (!todo) return 'Fehler'
          return `Bis ${moment(todo?.endDate).format('DD.MM.YYYY')}`
        }
        if (row.hierarchy.length === 3) {
          if (
            row.statusInfo == StatusInfo.Applied ||
            row.statusInfo == StatusInfo.ToBeVerified ||
            row.statusInfo == StatusInfo.Rejected ||
            row.statusInfo == StatusInfo.Reported
          )
            return ''
          const uploadTodos: Todo[] = row.completedTodos.filter((t: Todo) => t.type === TodoType.uploadContent)
          if (!uploadTodos || !uploadTodos.length) return 'Noch kein Upload'
          const todo: Todo = uploadTodos.reduce((prev, curr) => (prev.endDate < curr.endDate ? prev : curr))
          if (!todo.isCompleted) return 'Noch kein Upload'
          if (!todo.contentLinks || !todo.contentLinks.length) return 'Fehler'
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              Am {moment(todo?.completedTimestamp).format('DD.MM.YYYY')}
              <ImageVideoUpload value={todo.contentLinks[0].link} onUpload={() => {}} type='video' editable={false} height='50px' />
            </div>
          )
        }
        return ''
      }
    },
    {
      field: 'feedback',
      headerName: 'Feedback',
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params
        if (!row.hierarchy) return ''
        if (row.hierarchy.length === 2) {
          const todo: Todo = row.campaignTimeline.find((t: Todo) => t.type === TodoType.awaitContentFeedback)
          if (!todo) return 'Fehler'
          return `Bis ${moment(todo?.endDate).format('DD.MM.YYYY')}`
        }
        if (row.hierarchy.length === 3) {
          if (
            row.statusInfo == StatusInfo.Applied ||
            row.statusInfo == StatusInfo.ToBeVerified ||
            row.statusInfo == StatusInfo.Rejected ||
            row.statusInfo == StatusInfo.Reported
          )
            return ''
          const uploadTodos: Todo[] = row.completedTodos.filter((t: Todo) => t.type === TodoType.uploadContent)
          if (!uploadTodos || !uploadTodos.length) return 'Noch kein Upload'
          const firstUpload: Todo = uploadTodos.reduce((prev, curr) => (prev.startDate < curr.startDate ? prev : curr))
          if (!firstUpload.isCompleted) return 'Noch kein Upload'

          const todo: Todo = row.allTodos.find((t: Todo) => t.type === TodoType.awaitContentFeedback)
          if (!todo) return 'Fehler'
          console.log(todo, firstUpload)
          if (todo.isCompleted && !firstUpload.contentDenyReasons?.length) return <CheckIcon />
          return (
            <Tooltip title={firstUpload.contentDenyReasons?.map((r, index) => `${index + 1}: ${r}`).join('\n')}>
              <Typography pt='10px'> Gegeben am {moment(todo?.completedTimestamp).format('DD.MM.YYYY')}</Typography>
            </Tooltip>
          )
        }
        return ''
      }
    },
    {
      field: 'secondUpload',
      headerName: 'Überarbeitetes Video',
      width: 220,
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params
        if (!row.hierarchy) return ''

        if (row.hierarchy.length === 2) {
          const uploadTodos: Todo[] = row.campaignTimeline.filter((t: Todo) => t.type === TodoType.uploadContent)
          if (!uploadTodos || !uploadTodos.length) return 'Fehler'
          const todo: Todo = uploadTodos.reduce((prev, curr) => (prev.endDate > curr.endDate ? prev : curr))
          if (!todo) return 'Fehler'
          return `Bis ${moment(todo?.endDate).format('DD.MM.YYYY')}`
        }
        if (row.hierarchy.length === 3) {
          if (
            row.statusInfo == StatusInfo.Applied ||
            row.statusInfo == StatusInfo.ToBeVerified ||
            row.statusInfo == StatusInfo.Rejected ||
            row.statusInfo == StatusInfo.Reported
          )
            return ''
          const allTodos = [...row.completedTodos, ...row.openTodos]
          const uploadTodos: Todo[] = allTodos.filter((t: Todo) => t.type === TodoType.uploadContent)
          if (!uploadTodos || !uploadTodos.length) return 'Fehler'
          if (uploadTodos.length == 1 || !allTodos.find(t => t.type === TodoType.seeFeedback)) return <CheckIcon />
          const todo: Todo = uploadTodos.reduce((prev, curr) => (prev.endDate > curr.endDate ? prev : curr))
          if (!todo.isCompleted) return 'Noch kein Upload'
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              Am {moment(todo?.completedTimestamp).format('DD.MM.YYYY')}
              <ImageVideoUpload value={todo.contentLinks[0].link} onUpload={() => {}} type='video' editable={false} height='50px' />
            </div>
          )
        }
        return ''
      }
    }
    // {
    //   field: 'editing',
    //   headerName: 'Editing',
    //   width: 150,

    //   renderCell: (params: GridRenderCellParams) => {
    //     const { row } = params

    //     if (row.hierarchy.length === 2) {
    //       const uploadTodos: Todo[] = row.campaignTimeline.filter((t: Todo) => t.type === TodoType.uploadContent)
    //       if (!uploadTodos) return 'Fehler'
    //       const todo: Todo = uploadTodos.reduce((prev, curr) => (prev.endDate > curr.endDate ? prev : curr))
    //       if (!todo) return 'Fehler'
    //       return `Bis ${moment(todo?.endDate).add(2, 'days').format('DD.MM.YYYY')}`
    //     }
    //     if (row.hierarchy.length === 3) {
    //       if (row.statusInfo == StatusInfo.Applied || row.statusInfo == StatusInfo.ToBeVerified || row.statusInfo == StatusInfo.Rejected || row.statusInfo == StatusInfo.Reported) return ''
    //       const uploadTodos: Todo[] = [
    //         ...row.completedTodos.filter((t: Todo) => t.type === TodoType.uploadContent),
    //         ...row.openTodos.filter((t: Todo) => t.type === TodoType.uploadContent)
    //       ]
    //       if (!uploadTodos) return 'Fehler'
    //       const todo: Todo = uploadTodos.reduce((prev, curr) => (prev.endDate > curr.endDate ? prev : curr))
    //       if (!todo.isCompleted) return 'Noch kein Upload'
    //       return <>{todo.contentLinks.find(c => c.videoFeature)}</>
    //     }
    //     return ''
    //   }
    // }
  ]

  const handleViewInfluencer = (row: GridValidRowModel) => {
    const url = `/influencers?filterModel=%257B%2522items%2522%253A%255B%257B%2522field%2522%253A%2522influencerId%2522%252C%2522operator%2522%253A%2522contains%2522%252C%2522id%2522%253A94293%252C%2522value%2522%253A%2522${row.influencerId}%2522%252C%2522fromInput%2522%253A%2522%253Ar6n%253A%2522%257D%255D%257D`
    window.open(url, '_blank')
  }

  const handleViewChat = (row: GridValidRowModel) => {
    const url = `/chat?conversationId=${row.conversationId}`
    window.open(url, '_blank')
  }

  const handleViewBrand = (row: GridValidRowModel) => {
    const url = `${process.env.REACT_APP_WEB_URL}/${row.brandId}/dashboard`
    window.open(url, '_blank')
  }

  const handleViewCampaign = (row: GridValidRowModel) => {
    const url = `${process.env.REACT_APP_WEB_URL}/content/${row.brandId}/${row.campaignId}/creators`
    window.open(url, '_blank')
  }

  if (!rows) {
    return <CircularProgress />
  }

  const getTreeDataPath = (row: GridValidRowModel) => row.hierarchy
  const getRowClassName = (params: GridRowParams) => {
    const { hierarchy } = params.row
    if (hierarchy) {
      if (hierarchy.length === 1) return 'brand-row' // Brand level
      if (hierarchy.length === 2) return 'campaign-row' // Campaign level
      if (hierarchy.length === 3) return 'creator-row' // Creator level
    }
    return ''
  }

  const setCampaignOffline = async (row: GridValidRowModel) => {
    const { brandId, campaignId, title } = row
    const key = enqueueSnackbar(`Setze Kampagne ${title} auf abgeschlossen`, { variant: 'info' })
    await campaignService.setCampaignOffline({ brandId, campaignId })
    closeSnackbar(key)
    enqueueSnackbar(`Kampagne ${title} abgeschlossen`, { variant: 'success' })

    const campaignRows = rows.filter(r => r.brandId === brandId && r.campaignId && !r.influencerId)
    if (campaignRows && campaignRows.length == 1) {
      setRows([...rows.filter(r => r.brandId !== brandId)]) // if only one campaign remove also the brand and creators
    } else {
      setRows([...rows.filter(r => r.campaignId !== campaignId)]) // remove only campaign and creators
    }
  }

  const groupingColDef: GridGroupingColDefOverride = {
    headerName: 'Brand/Campaign/Creator',
    valueFormatter: (_, row) => {
      const { hierarchy, brandName, title, first_name } = row

      if (!hierarchy) return ''
      if (hierarchy.length === 1) {
        // Adjust label based on hierarchy level
        // Brand level: Count campaigns
        return (
          <>
            <Tooltip title='Zur Brand'>
              <b onClick={() => handleViewBrand(row)} style={{ cursor: 'pointer' }}>
                {brandName}
              </b>
            </Tooltip>{' '}
            ({row.campaigns.length})
          </>
        )
      } else if (hierarchy.length === 2) {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title='Zum Auftrag'>
              <b onClick={() => handleViewCampaign(row)} style={{ cursor: 'pointer' }}>
                {title}
              </b>
            </Tooltip>
            <Tooltip title={`Auftrag hat ${row.numberOfVideos} gebucht und ${row.numberOfVideos - row.missingNumberOfVideos} Creator.`}>
              <Typography sx={{ marginLeft: '4px' }}>
                {row.numberOfVideos - row.missingNumberOfVideos}/{row.numberOfVideos}
              </Typography>
            </Tooltip>
            <Tooltip title='Gesamte Creator'>
              <Typography sx={{ marginLeft: '4px' }}>({row.creators.length})</Typography>
            </Tooltip>
            <Tooltip title='Kampagnen Kommentare' sx={{ marginLeft: '8px' }}>
              <CommentIcon
                fontSize='medium'
                onClick={event => {
                  event.stopPropagation()
                  setCommentRow(row)
                }}
              />
            </Tooltip>
            <Tooltip title='Offline nehmen' sx={{ marginLeft: '8px' }}>
              <IconButton onClick={() => setCampaignOffline(row)}>
                <CloudOffIcon />
              </IconButton>
            </Tooltip>
          </div>
        )
      } else if (hierarchy.length === 3) {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title='Zum Creator'>
              <b onClick={() => handleViewInfluencer(row)} style={{ cursor: 'pointer' }}>
                {first_name}
              </b>
            </Tooltip>
            {row.conversationId && (
              <Tooltip title='Zum Chat'>
                <SendIcon fontSize='medium' sx={{ cursor: 'pointer', marginLeft: '8px' }} onClick={() => handleViewChat(row)} />
              </Tooltip>
            )}
            <Tooltip title='Creator Kommentare'>
              <CommentIcon
                sx={{ cursor: 'pointer', marginLeft: '8px' }}
                fontSize='medium'
                onClick={event => {
                  event.stopPropagation()
                  setCommentRow(row)
                }}
              />
            </Tooltip>
          </div>
        )
      }

      return '' // No additional label for creators
    },
    hideDescendantCount: true,
    width: 450,
    filterable: true,
    disableColumnMenu: false,
    aggregable: false,
    pinnable: false,
    hideable: false,
    valueGetter: (value, row, column, apiRef) => {
      const rowId = apiRef.current.getRowId(row)
      const rowNode = apiRef.current.getRowNode(rowId) as any

      if (row.hierarchy?.length === 1) {
        return row.brandName
      } else if (row.herarchy?.length === 2) {
        return row.brandName + ' - ' + row.title
      } else if (row.hierarchy?.length === 3) {
        return row.brandName + ' - ' + row.title + ' - ' + row.first_name
      }

      return rowNode?.groupingKey ?? ''
    }
  }

  const getRowHeight = (params: GridRowHeightParams) => {
    const { id } = params
    const hierarchy = rows.find(row => row.id === id)?.hierarchy
    return hierarchy?.length === 1 ? 80 : hierarchy?.length === 2 ? 60 : 50
  }

  const renderCommentDialog = () => {
    const row = commentRow
    if (!row) return

    const { campaignId, influencerId, hierarchy, campaignComments, creatorComments } = row
    const id = hierarchy.length === 3 ? `${campaignId}_${influencerId}` : campaignId

    return (
      <CommentDialog
        campaignId={campaignId}
        influencerId={hierarchy.length === 3 ? influencerId : undefined}
        comments={hierarchy.length === 3 ? creatorComments : campaignComments}
        id={id}
        open={commentRow ? id : false}
        onClose={() => setCommentRow(undefined)}
      />
    )
  }

  return (
    <>
      {renderCommentDialog()}
      <PersistedDataGrid
        rows={rows}
        columns={columns}
        initialState={initialState}
        getTreeDataPath={getTreeDataPath}
        treeData
        getRowClassName={getRowClassName}
        groupingColDef={groupingColDef}
        getRowHeight={getRowHeight}
      />
    </>
  )
}

export default Dashboard
