import React, { useState } from 'react'
import { Dialog, DialogContent, DialogTitle, Tooltip } from '@mui/material'
import { Comments } from './Comments'
import { Comment } from '../../types/types'
import { InfoOutlined } from '@mui/icons-material'

type Props = {
  comments: Comment[]
  id: string
  campaignId: string
  influencerId?: string
  open: string | false
  onClose: (comments: Comment[]) => void
}
const CommentDialog = (props: Props) => {
  const { open, comments, id, campaignId, influencerId, onClose } = props
  const [commentsState, setComments] = useState(comments)

  const handleCommentsChange = (comments: Comment[]) => {
    setComments(comments)
  }

  return (
    <Dialog open={open === id} onClose={() => onClose(commentsState)} fullWidth>
      <DialogTitle>
        Comments{' '}
        <Tooltip title='Kommentare werden erst wieder nach Neuladen angezeigt.'>
          <InfoOutlined />
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        <Comments comments={commentsState} handleCommentsChange={handleCommentsChange} campaignId={campaignId} influencerId={influencerId} />
      </DialogContent>
    </Dialog>
  )
}

export default CommentDialog
