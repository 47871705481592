import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import Box from '@mui/material/Box'
import {
  DataGridPremium,
  GridActionsCellItem,
  GridColDef,
  GridColumnVisibilityModel,
  GridEventListener,
  GridFilterModel,
  GridGroupNode,
  GridRowClassNameParams,
  GridRowEditStopReasons,
  GridRowHeightParams,
  GridRowHeightReturnValue,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowSelectionModel,
  GridRowsProp,
  GridToolbarContainer,
  GridToolbarExport,
  GridValidRowModel
} from '@mui/x-data-grid-premium'
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium'
import { enqueueSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ImageVideoUpload from './ImageVideoUpload'
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'

export type CustomGridColDef = Omit<GridColDef, 'type'> & {
  type?: 'image' | 'video' | 'singleSelect' | GridColDef['type']
  valueOptions?: string[] | number[] | { value: string | number; label: string }[]
}

interface CustomDataGridProps {
  rows?: GridRowsProp
  columns: CustomGridColDef[]
  onDelete?: (id: GridRowId) => Promise<void>
  onSave?: (row: GridRowModel) => Promise<GridRowModel | undefined>
  onUpload?: (id: GridRowId, file: File) => Promise<void>
  initialState?: GridInitialStatePremium | undefined
  getTreeDataPath?: ((row: GridValidRowModel) => string[]) | undefined
  treeData?: boolean | undefined
  getRowClassName?: ((params: GridRowClassNameParams<GridValidRowModel>) => string) | undefined
  groupingColDef?: any
  getRowHeight?: ((params: GridRowHeightParams) => GridRowHeightReturnValue) | undefined
  onRowClick?: GridEventListener<'rowClick'> | undefined
  apiRef?: React.MutableRefObject<GridApiPremium> | undefined
  isGroupExpandedByDefault?: ((node: GridGroupNode) => boolean) | undefined
  onRowSelectionModelChange?: (selectionModel: GridRowSelectionModel) => void
}

const CustomDataGrid = ({
  rows,
  columns,
  onDelete,
  onSave,
  onUpload,
  initialState,
  getTreeDataPath,
  treeData,
  getRowClassName,
  groupingColDef,
  getRowHeight,
  onRowClick,
  apiRef,
  isGroupExpandedByDefault,
  onRowSelectionModelChange
}: CustomDataGridProps) => {
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] })
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({})

  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const filterParam = searchParams.get('filterModel')
    const hiddenColumnsParam = searchParams.get('hiddenColumns')

    if (filterParam) {
      const parsedFilterModel = JSON.parse(decodeURIComponent(filterParam))
      setFilterModel(parsedFilterModel)
      localStorage.setItem('filterModel', JSON.stringify(parsedFilterModel))
    } else {
      const savedFilterModel = localStorage.getItem('filterModel')
      if (savedFilterModel) {
        setFilterModel(JSON.parse(savedFilterModel))
      }
    }

    if (hiddenColumnsParam) {
      const parsedHiddenColumns = JSON.parse(decodeURIComponent(hiddenColumnsParam))
      const visibilityModel = parsedHiddenColumns.reduce((model: GridColumnVisibilityModel, col: string) => {
        model[col] = false
        return model
      }, {})
      setColumnVisibilityModel(visibilityModel)
      localStorage.setItem('hiddenColumns', JSON.stringify(parsedHiddenColumns))
    } else {
      const savedHiddenColumns = localStorage.getItem('hiddenColumns')
      if (savedHiddenColumns) {
        const hiddenCols = JSON.parse(savedHiddenColumns)
        const visibilityModel = hiddenCols.reduce((model: GridColumnVisibilityModel, col: string) => {
          model[col] = false
          return model
        }, {})
        setColumnVisibilityModel(visibilityModel)
      }
    }
  }, [])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const currentFilterParam = searchParams.get('filterModel')
    const currentHiddenColumnsParam = searchParams.get('hiddenColumns')

    const newFilterParam = filterModel.items.length > 0 ? encodeURIComponent(JSON.stringify(filterModel)) : null
    const hiddenCols = Object.keys(columnVisibilityModel).filter(col => !columnVisibilityModel[col])
    const newHiddenColumnsParam = hiddenCols.length > 0 ? encodeURIComponent(JSON.stringify(hiddenCols)) : null

    let shouldNavigate = false

    if (newFilterParam && newFilterParam !== currentFilterParam) {
      searchParams.set('filterModel', newFilterParam)
      shouldNavigate = true
    } else if (!newFilterParam && currentFilterParam) {
      searchParams.delete('filterModel')
      shouldNavigate = true
    }

    if (newHiddenColumnsParam && newHiddenColumnsParam !== currentHiddenColumnsParam) {
      searchParams.set('hiddenColumns', newHiddenColumnsParam)
      shouldNavigate = true
    } else if (!newHiddenColumnsParam && currentHiddenColumnsParam) {
      searchParams.delete('hiddenColumns')
      shouldNavigate = true
    }

    if (shouldNavigate) {
      navigate({ search: searchParams.toString() }, { replace: true })
    }

    // Only update local storage if there was a real change
    if (newFilterParam !== currentFilterParam) {
      localStorage.setItem('filterModel', JSON.stringify(filterModel))
    }

    if (newHiddenColumnsParam !== currentHiddenColumnsParam) {
      localStorage.setItem('hiddenColumns', JSON.stringify(hiddenCols))
    }
  }, [filterModel, columnVisibilityModel, navigate, location.search])

  useEffect(() => {
    if (initialState && initialState.columns && initialState.columns.columnVisibilityModel) {
      setColumnVisibilityModel(initialState.columns.columnVisibilityModel)
    }
  }, [])

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = (id: GridRowId) => () => {
    if (!onSave) return
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleDeleteClick = (id: GridRowId) => () => {
    if (!onDelete) return
    onDelete(id) // Call the onDelete callback
  }

  const handleUpload = (id: GridRowId, file: File) => {
    if (!onUpload) return
    alert(`Selected file: ${file.name}`)
    // Implement your upload logic here
    if (onUpload) {
      onUpload(id, file)
    }
  }

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })
  }

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  const handleFilterModelChange = (newFilterModel: GridFilterModel) => {
    setFilterModel(newFilterModel)
  }

  const handleColumnVisibilityModelChange = (newModel: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(newModel)
  }

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  const actionColumn: GridColDef | undefined = onDelete &&
    onSave && {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem key={id} icon={<SaveIcon />} label='Save' sx={{ color: 'primary.main' }} onClick={handleSaveClick(id)} />,
            <GridActionsCellItem key={id} icon={<CancelIcon />} label='Cancel' className='textPrimary' onClick={handleCancelClick(id)} color='inherit' />
          ]
        }

        return [
          <GridActionsCellItem key={id} icon={<EditIcon />} label='Edit' className='textPrimary' onClick={handleEditClick(id)} color='inherit' />,
          <GridActionsCellItem key={id} icon={<DeleteIcon />} label='Delete' onClick={handleDeleteClick(id)} color='inherit' />
        ]
      }
    }
  const enhancedColumns = columns.map(col => {
    if (col.type === 'image' || col.type === 'video') {
      return {
        ...col,
        renderCell: params =>
          (col.type === 'image' || col.type === 'video') && (
            <ImageVideoUpload value={params.value} onUpload={file => handleUpload(params.id, file)} type={col.type} editable={params.isEditable ?? false} />
          )
      }
    }
    return col
  }) as GridColDef[]

  if (rows?.length === 0) return <Box>Keine Daten gefunden</Box>

  return (
    <Box sx={{ width: '100%', height: '800px' }}>
      <DataGridPremium
        rows={rows ? rows : []}
        columns={[...enhancedColumns, actionColumn].filterNotNull()}
        loading={!rows}
        editMode='row'
        checkboxSelection
        slots={{
          toolbar: () => (
            <GridToolbarContainer>
              <GridToolbarExport
                csvOptions={{
                  fileName: 'customerDataBase',
                  delimiter: ';',
                  utf8WithBom: true
                }}
              />
            </GridToolbarContainer>
          )
        }}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowSelectionModelChange={onRowSelectionModelChange}
        filterModel={filterModel}
        onFilterModelChange={handleFilterModelChange}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={async (updatedRow: GridRowModel) => onSave && (await onSave(updatedRow))}
        onProcessRowUpdateError={error => enqueueSnackbar('Fehler beim Speichern der Zeile: ' + error, { variant: 'error' })}
        initialState={initialState}
        getTreeDataPath={getTreeDataPath}
        treeData={treeData}
        getRowClassName={getRowClassName}
        groupingColDef={groupingColDef}
        getRowHeight={getRowHeight}
        onRowClick={onRowClick}
        apiRef={apiRef}
        isGroupExpandedByDefault={isGroupExpandedByDefault}
      />
    </Box>
  )
}

export default CustomDataGrid
