import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'
import { GridRowId, GridRowModel, GridRowSelectionModel, GridRowsProp } from '@mui/x-data-grid-premium'
import { closeSnackbar, enqueueSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { BroadcastMessageModal } from '../../components/chat/BroadcastMessageModal'
import PersistedDataGrid, { CustomGridColDef } from '../../components/dataGrid/PersistedDataGrid'
import InfluencerService from '../../service/InfluencerService'
import { Influencer, UGCStatus } from '../../types/types'
import { extractUserMessageFromError } from '../../util'
import { CampaignsColumn } from './CampaignsColumn'

export const InfluencerList = () => {
  const [spoofInfluencerId, setSpoofInfluencerId] = useState<string | undefined>(undefined)
  const [spoofAsEmail, setSpoofAsEmail] = useState<string>('')
  const [spoofLoading, setSpoofLoading] = useState<boolean>(false)
  const [rows, setRows] = useState<GridRowsProp | undefined>(undefined)
  const [selectedInfluencers, setSelectedInfluencers] = useState<GridRowId[]>([]) // State for selected influencers
  const [showBroadcastModal, setShowBroadcastModal] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState<GridRowId | undefined>(undefined)
  const [selectedInfluencersData, setSelectedInfluencersData] = useState<Influencer[]>([])
  const [allInfluencersData, setAllInfluencersData] = useState<Influencer[]>([])

  console.log({ selectedInfluencers })

  useEffect(() => {
    const fetchInfluencers = async () => {
      const influencers = await InfluencerService.getAllInfluencers({})
      setRows(
        influencers.map(i => ({
          id: i.influencerId,
          ...i,
          birth_date: i.birth_date ? new Date(i.birth_date) : undefined, // We can also use valueGetter for this:
          contentCV: i.defaultContentCV && i.defaultContentCV[0] ? i.defaultContentCV[0] : undefined
        })) as GridRowsProp
      )
      setAllInfluencersData(influencers)
    }

    fetchInfluencers()
  }, [])

  const handleDelete = async (id: GridRowId) => setShowDeleteDialog(id)

  // TODO use GridAPIRef
  const handleConfirmDelete = async () => {
    const row = rows?.find(r => r.id === showDeleteDialog)
    if (!row) {
      enqueueSnackbar('Fehler aufgetreten', { variant: 'error' })
      return
    }

    const key = enqueueSnackbar('Löschen lädt', { variant: 'info', autoHideDuration: null })

    try {
      await InfluencerService.deleteInfluencer({ influencerId: row.influencerId })
      setRows(rows?.filter(row => row.id !== showDeleteDialog))
      enqueueSnackbar('Löschen hat funktioniert')
    } catch (e) {
      const message = extractUserMessageFromError(e)
      enqueueSnackbar(message || 'Fehler beim Löschen', { variant: 'error' })
      console.error(e)
    } finally {
      closeSnackbar(key)
      setShowDeleteDialog(undefined)
    }
  }

  // TODO use GridAPIRef
  const handleSave = async (updatedRow: GridRowModel) => {
    const key = enqueueSnackbar('Creator wird geupdated', { variant: 'info', autoHideDuration: null })
    try {
      const { influencerId } = updatedRow
      await InfluencerService.updateInfluencer({ influencerId, ...updatedRow })

      setRows(rows?.map(row => (row.id === updatedRow.id ? updatedRow : row)))

      enqueueSnackbar('Update hat funktioniert', { variant: 'success' })
      return updatedRow
    } catch (e) {
      enqueueSnackbar('Fehler bei Update', { variant: 'error' })
    } finally {
      closeSnackbar(key)
    }
  }

  const handleViewInvoices = (influencerId: string) => {
    const url = `/invoices?influencerId=${influencerId}`
    window.open(url, '_blank')
  }

  const handleViewChat = (conversationId: string) => {
    const url = `/chat?conversationId=${conversationId}`
    window.open(url, '_blank')
  }

  const handleOpenSpoof = (influencerId: string) => {
    const influencer = rows?.find(i => i.influencerId === influencerId)
    if (!influencer) {
      enqueueSnackbar('Fehler aufgetreten', { variant: 'error' })
      return
    }
    setSpoofAsEmail(influencer.spoofedByAdmin ?? '')
    setSpoofInfluencerId(influencerId)
  }

  const handleCloseSpoof = () => {
    setSpoofAsEmail('')
    setSpoofInfluencerId(undefined)
  }

  const handleSpoofAs = async () => {
    if (!spoofInfluencerId) {
      enqueueSnackbar('Fehler aufgetreten', { variant: 'error' })
      return
    }
    setSpoofLoading(true)
    try {
      await InfluencerService.spoofAsInfluencer({
        adminEmail: spoofAsEmail,
        spoofAs: spoofInfluencerId
      })

      setRows(
        rows?.map(row => {
          if (spoofAsEmail && row.spoofedByAdmin == spoofAsEmail) return { ...row, spoofedByAdmin: '' }
          if (row.influencerId != spoofInfluencerId) return row
          return { ...row, spoofedByAdmin: spoofAsEmail }
        })
      )
      enqueueSnackbar('Hat geklappt.', { variant: 'success' })
    } catch (e: any) {
      if (e && e.message && e.message.includes('Influencer with email')) {
        enqueueSnackbar('Kein Influencer der Email existiert', { variant: 'error' })
      } else {
        enqueueSnackbar('Fehler aufgetreten', { variant: 'error' })
      }
    }
    setSpoofLoading(false)
    handleCloseSpoof()
  }

  const onRowSelectionModelChange = (newSelection: GridRowSelectionModel) => {
    setSelectedInfluencers(newSelection)
    const filtered = allInfluencersData.filter(data => newSelection.some(id => data.influencerId === id))
    setSelectedInfluencersData(filtered)
  }

  const handleOpenBroadcastModal = () => {
    setShowBroadcastModal(true)
  }

  // Handler to close BroadcastMessageModal
  const handleCloseBroadcastModal = () => {
    setShowBroadcastModal(false)
  }

  const influencerColumns: CustomGridColDef[] = [
    { field: 'influencerId', headerName: 'ID', width: 150, aggregable: false, groupable: false },
    { field: 'userId', headerName: 'Stylink ID', width: 150, aggregable: false, groupable: false },
    { field: 'conversationId', headerName: 'Chat ID', width: 1, editable: false },
    { field: 'first_name', headerName: 'Vorname', width: 150, editable: false },
    { field: 'last_name', headerName: 'Nachname', width: 150, editable: false },
    { field: 'email', headerName: 'Email', width: 150, editable: false },
    { field: 'mobile', headerName: 'Phone', width: 150, editable: false },
    { field: 'street', headerName: 'Address', width: 150, editable: false },
    { field: 'city', headerName: 'City', width: 150, editable: false },
    { field: 'zip', headerName: 'Zip', width: 150, editable: false },
    { field: 'country', headerName: 'Country', width: 150, editable: false },
    { field: 'birth_date', headerName: 'Geburtsdatum', type: 'date', width: 150, editable: false },
    { field: 'gender', headerName: 'Geschlecht', width: 150, editable: false },
    { field: 'age', headerName: 'Alter', type: 'number', width: 150, editable: false },
    { field: 'image', headerName: 'Image URL', width: 150, editable: false, type: 'image' },
    { field: 'contentCV', headerName: 'Beispielvideo', width: 150, editable: false, type: 'video' },
    { field: 'avgUGCTime', headerName: 'avgUGCTime', width: 150, editable: false },

    {
      field: 'ugcStatus',
      headerName: 'UGC Status',
      width: 150,
      editable: true,
      type: 'singleSelect',
      valueOptions: [UGCStatus.Accepted, UGCStatus.Premium, UGCStatus.Banned]
    },
    { field: 'isFavourite', headerName: 'Favourite', width: 100, editable: true, type: 'boolean' },
    { field: 'isShadowBanned', headerName: 'Shadow Banned', type: 'boolean', width: 150, editable: true },
    { field: 'isActive', headerName: 'Active', type: 'boolean', width: 100, editable: false },
    {
      field: 'viewInvoices',
      headerName: 'View Invoices',
      width: 200,
      renderCell: params =>
        params.row.id && (
          <Button variant='contained' color='primary' onClick={() => handleViewInvoices(params.row.influencerId)}>
            View Invoices
          </Button>
        )
    },
    {
      field: 'openChat',
      headerName: 'Chat',
      width: 200,
      renderCell: params =>
        params.row.conversationId && (
          <Button variant='contained' color='primary' onClick={() => handleViewChat(params.row.conversationId)}>
            Zum Chat
          </Button>
        )
    },
    {
      field: 'aufträge',
      headerName: 'Aufträge',
      width: 200,
      renderCell: params => <CampaignsColumn params={params} />
    },

    {
      field: 'spoofAs',
      headerName: 'Spoof',
      width: 200,
      renderCell: params =>
        params.row.conversationId && (
          <Button variant={params.row.spoofedByAdmin ? 'outlined' : 'contained'} color='primary' onClick={() => handleOpenSpoof(params.row.influencerId)}>
            {params.row.spoofedByAdmin ? 'Bereits Spoofed' : 'Spoof'}
          </Button>
        )
    }
  ]

  const renderDeletionModal = () => (
    <Dialog open={!!showDeleteDialog} onClose={() => setShowDeleteDialog(undefined)}>
      <DialogTitle>Willst du den Creator wirklich löschen?</DialogTitle>
      <DialogActions>
        <Button onClick={() => setShowDeleteDialog(undefined)}>Abbrechen</Button>
        <LoadingButton variant='contained' onClick={async () => await handleConfirmDelete()}>
          Bestätigen
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )

  const renderSpoofingModal = () => (
    <Dialog open={!!spoofInfluencerId} onClose={() => handleCloseSpoof()}>
      <DialogTitle>Spoof deinen Account.</DialogTitle>
      <DialogContent>
        Gib hier deine Account Email Addresse ein (App), mit der du dich als der Influencer ausgeben willst.
        <Grid xs={12} pt={2}>
          <TextField
            label='Email'
            placeholder='email@stylink.com'
            fullWidth
            value={spoofAsEmail}
            onChange={e => {
              const value = e.currentTarget?.value || ''
              setSpoofAsEmail(value)
            }}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCloseSpoof()}>Abbrechen</Button>
        <LoadingButton variant='contained' onClick={async () => await handleSpoofAs()} loading={spoofLoading}>
          Bestätigen
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )

  return (
    <>
      {renderSpoofingModal()}
      {renderDeletionModal()}
      <Button variant='contained' color='primary' onClick={handleOpenBroadcastModal} disabled={selectedInfluencers.length === 0} sx={{ mb: 2 }}>
        Broadcast Nachricht erstellen
      </Button>
      <PersistedDataGrid rows={rows} columns={influencerColumns} onRowSelectionModelChange={onRowSelectionModelChange} onSave={handleSave} onDelete={handleDelete} />

      {/* BroadcastMessageModal */}
      <BroadcastMessageModal open={showBroadcastModal} onClose={handleCloseBroadcastModal} influencers={selectedInfluencersData} />
    </>
  )
}
