import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import CampaignService from '../../service/CampaignService';
import { MessageType, UGCStatus } from '../../types/types';
import agent from '../../agent';
import { bucketUrl, cloudfrontUrl, extractUserMessageFromError, State, uploadToS3 } from '../../util';
import InfluencerService from '../../service/InfluencerService';
import { InputFile } from '../common/InputFile';
import CommentDialog from '../comments/CommentDialog';
import { InfluencerContentGrid } from './InfluencerContentGrid';
import { DenyDialog } from './DenyDialog';

type Content = Awaited<ReturnType<typeof CampaignService.getContentCheck>>[number]

const ContentDataGrid = ({ content, setContents }: { content: Content } & State<'contents', Content[]>) => {
    const {
      campaignId,
      influencerId,
      todoId,
      brandId,
      comments
    } = content

  const [managing, setManaging] = useState(false);
  const [openDeny, setOpenDeny] = useState(false);
  const [open, setOpen] = useState(false);
  const [openComment, setOpenComment] = useState<string | false>(false)
  const [selectedContent, setSelectedContent] = useState<Content | null>(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const sendFeedback = async (influencerId: string, denyReasons: string[]) => {
    const { conversationId } = await agent.Chat.createConversation({ influencerId });
    await agent.Chat.sendMessage({ 
      conversationId, 
      content: `${denyReasons.join('\n')}`, 
      type: MessageType.text 
    });
  };

  const manageContent = async (content: Content, { accept, denyReasons }: { accept: boolean; denyReasons: string[] }) => {
    setManaging(true);
    const name = accept ? 'Accepting' : 'Denying';
    const key = enqueueSnackbar(name, { variant: 'info' });
    try {
      await CampaignService.approveInfluencerContent({ 
        influencerId: content.influencerId, 
        campaignId: content.campaignId 
      });
      if (!accept) await sendFeedback(content.influencerId, denyReasons);
      setContents(contents => contents.filter(c => c !== content));
    } catch (e) {
      const userError = extractUserMessageFromError(e);
      enqueueSnackbar(userError || `Error while ${name}`, { variant: 'error' });
    }
    
    closeSnackbar(key);
    setManaging(false);
  };

  const ban = async (content: Content, denyReasons: string[]) => {
    setManaging(true);
    const key = enqueueSnackbar('Banning', { variant: 'info' });
    
    try {
      await CampaignService.deleteCampaignInfluencer({
        campaignId: content.campaignId,
        todoId: content.todoId,
        influencerId: content.influencerId
      });
      await InfluencerService.denyInfluencer({
        influencerId: content.influencerId,
        ugcStatus: UGCStatus.Banned
      });
      await sendFeedback(content.influencerId, denyReasons);
      setContents(contents => contents.filter(c => c !== content));
    } catch (e) {
      const userError = extractUserMessageFromError(e);
      enqueueSnackbar(userError || `Error`, { variant: 'error' });
    }
    
    closeSnackbar(key);
    setManaging(false);
  };

  const handleOpenComment = (value: string | false) => setOpenComment(value)
  const handleManageContent = (content: Content, action: string) => {
    setSelectedContent(content);
    switch (action) {
      case 'deny':
        setOpenDeny(true);
        break;
      case 'accept':
        manageContent(content, { accept: true, denyReasons: [] });
        break;
      case 'upload':
        setOpen(true);
        break;
      case 'comment':
        handleOpenComment(openComment === `${campaignId}_${influencerId}` ? false : `${campaignId}_${influencerId}`);
        break;
      case 'reset':
        reset(content);
        break;
      default:
        break;
    }
  };
  

  const reset = async (content: Content) => {
    setManaging(true);
    const key = enqueueSnackbar('Resetting', { variant: 'info' });
    try {
      await CampaignService.resetUploadTodo({
        campaignId: content.campaignId,
        influencerId: content.influencerId,
        todoId: content.todoId
      });
      setContents(contents => contents.filter(c => c !== content));
    } catch (e) {
      const userError = extractUserMessageFromError(e);
      enqueueSnackbar(userError || `Error`, { variant: 'error' });
    }
    closeSnackbar(key);
    setManaging(false);
  };

  const upload = async (file: File) => {
    if (!selectedContent) return;
    
    setManaging(true);
    const key = enqueueSnackbar('Uploading', { variant: 'info', persist: true });
    
    try {
      const url = (await CampaignService.getMediaUploadUrl({
        brandId: brandId,
        influencerId: influencerId,
        campaignId: campaignId
      })).url;
      await uploadToS3(url, file);
      await CampaignService.resetUploadTodo({
        campaignId: campaignId,
        influencerId: influencerId,
        todoId: todoId,
        content: url
      });
      
      setContents(contents => {
        const updatedContent = contents.find(c => c === selectedContent);
        if (updatedContent?.secondContent) {
          updatedContent.secondContent = url.replace(bucketUrl, cloudfrontUrl);
        } else if (updatedContent?.firstContent) {
          updatedContent.firstContent = url.replace(bucketUrl, cloudfrontUrl);
        }
        return [...contents];
      });
    } catch (e) {
      const userError = extractUserMessageFromError(e);
      enqueueSnackbar(userError || `Error`, { variant: 'error' });
    }
    
    closeSnackbar(key);
    setManaging(false);
    setOpen(false);
  };

  return (
    <Box>
        <InfluencerContentGrid
          key={`${content.campaignId}_${content.influencerId}`}
          content={content}
          setContents={setContents}
          onManage={handleManageContent}
        />
      {selectedContent && (
        <>
          <DenyDialog
            open={openDeny}
            setOpen={setOpenDeny}
            deny={(reasons) =>
              manageContent(selectedContent, { accept: false, denyReasons: reasons })
            }
          />
          
          <InputFile
            open={open}
            setOpen={setOpen}
            onFile={upload}
          />
          
          {openComment === `${campaignId}_${influencerId}` && (
          <CommentDialog
            id={`${campaignId}_${influencerId}`}
            open={openComment}
            onClose={() => handleOpenComment(false)}
            comments={comments}
            campaignId={campaignId}
            influencerId={influencerId}
          />
        )}
        </>
      )}
    </Box>
  );
};

export default ContentDataGrid;