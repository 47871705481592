import { Grid, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'
import { useChat } from '../../context/ChatContext'
import { MessageFieldWithPresets } from '../todoOverview/ChatView'
import { ConversationPreviews } from './ConversationPreviews'
import { CreateConversationView } from './CreateConversationView'
import { MessagesView } from './MessagesView'
import { CreateBroadcast } from './CreateBroadCast'

const Chat = () => {
  const { selectedConvo } = useChat()

  const handleViewInfluencer = () => {
    const url = `/influencers?filterModel=%257B%2522items%2522%253A%255B%257B%2522field%2522%253A%2522influencerId%2522%252C%2522operator%2522%253A%2522contains%2522%252C%2522id%2522%253A94293%252C%2522value%2522%253A%2522${selectedConvo?.influencerId}%2522%252C%2522fromInput%2522%253A%2522%253Ar6n%253A%2522%257D%255D%257D`
    window.open(url, '_blank')
  }

  const renderConvoHeader = () => {
    if (!selectedConvo) return <></>
    return (
      <ListItemButton onClick={() => handleViewInfluencer()}>
        <ListItemIcon>
          <img
            src={selectedConvo?.image}
            style={{
              borderRadius: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
              height: 60,
              width: 60,
              borderColor: 'black'
            }}
          />
        </ListItemIcon>
        <ListItemText primary={selectedConvo.name} sx={{ ml: 10 }} />
      </ListItemButton>
    )
  }

  return (
    <Grid container>
      <Grid item xs={3} alignItems={'center'} borderRight='black'>
        <CreateConversationView />
        <Grid marginTop={1}>
        <CreateBroadcast />
        </Grid>
        <ConversationPreviews />
      </Grid>
      <Grid item xs={9}>
        {selectedConvo && (
          <>
            {renderConvoHeader()}
            <MessagesView />
            <MessageFieldWithPresets />
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default Chat
