import {
  faAddressBook,
  faBullhorn,
  faCheck,
  faFeather,
  faFileInvoice,
  faPhotoVideo,
  faUserPlus,
  faUsers,
  faVideo,
  faMoneyBill
} from '@fortawesome/free-solid-svg-icons'

export enum PageTypes {
  BRANDS,
  INFLUENCERS,
  STATISTICS
}

export const pages = [
  {
    title: 'Kampagnen',
    description: 'Gib neue Kampagnen frei oder überprüfe die Aktuellen',
    value: 'campaigns',
    icon: faBullhorn,
    type: PageTypes.BRANDS
  },
  {
    title: 'Influencer Annehmen',
    description: 'Verifiziere neue Influencer',
    value: 'influencers-new',
    icon: faUserPlus,
    type: PageTypes.INFLUENCERS
  },
  {
    title: 'Influencer',
    description: 'Liste aller Influencer',
    value: 'influencers',
    icon: faUsers,
    type: PageTypes.INFLUENCERS
  },
  {
    title: 'Überweisungen',
    description: 'Übersicht über alle Überweisungen an Influencer',
    value: 'transactions',
    icon: faFileInvoice,
    type: PageTypes.INFLUENCERS
  },
  { title: 'Todo Overview', description: 'manage todos', value: 'todo-overview', icon: faCheck, type: PageTypes.BRANDS },
  { title: 'Dashboard', description: 'Dashboard', value: 'dashboard', icon: faCheck, type: PageTypes.BRANDS },
  { 
    title: 'Content Check',
    description: 'Manage 2nd uploads',
    value: 'content-check',
    icon: faPhotoVideo,
    type: PageTypes.INFLUENCERS
  },
  {
    title: 'Chat',
    description: 'Chat with others',
    value: 'chat',
    icon: faAddressBook,
    type: PageTypes.INFLUENCERS
  },
  {
    title: 'Video Editing',
    description: 'Send edited content to brands',
    value: 'video-editing',
    icon: faVideo,
    type: PageTypes.INFLUENCERS
  },
  {
    title: 'Internal Feedback',
    description: 'Send internal feedbacks to todos',
    value: 'internal-feedback',
    icon: faFeather,
    type: PageTypes.INFLUENCERS
  },
  {
    title: 'Upload Credit',
    description: 'Upload Extra Credit (Toll etc.)',
    value: 'credit',
    icon: faMoneyBill,
    type: PageTypes.INFLUENCERS
  }
]
