import { useSnackbar } from "notistack"
import React, { useEffect, useState } from "react"
import agent from "../../agent"

export interface ChatPreset {
    content: string
    title: string
    presetId: string
  }

type ChatPresetMapper = any

export const usePresets = () => {
    const [presets, setPresets] = useState<ChatPreset[]>([])
    const [loading, setLoading] = useState(false)
    const { enqueueSnackbar } = useSnackbar()
  
  
    const fetchPresets = async () => {
      try {
        setLoading(true)
        const response = await agent.Chat.getChatPresets({})
        const mappedResponse = response.map((preset: ChatPresetMapper) => ({
            content: preset.content,
            title: preset.title,
            presetId: preset.presetId
        }))
        setPresets(mappedResponse)
      } catch (error) {
        enqueueSnackbar('Failed to load presets', { variant: 'error' })
      } finally {
        setLoading(false)
      }
    }
  
    const savePreset = async (preset: Omit<ChatPreset, 'id' | 'created_at' | 'updated_at'>) => {
      try {
        setLoading(true)
        await agent.Chat.saveChatPreset({ content: preset.content,title: preset.title })
        await fetchPresets() // Refresh presets after saving
        enqueueSnackbar('Preset saved successfully', { variant: 'success' })
        return true
      } catch (error) {
        enqueueSnackbar('Failed to save preset', { variant: 'error' })
        return false
      } finally {
        setLoading(false)
      }
    }

    const deletePreset = async (presetId: string) => {
        try {
          setLoading(true)
          await agent.Chat.deleteChatPreset({ presetId })
          await fetchPresets() // Refresh presets after deletion
          enqueueSnackbar('Preset deleted successfully', { variant: 'success' })
          return true
        } catch (error) {
          enqueueSnackbar('Failed to delete preset', { variant: 'error' })
          return false
        } finally {
          setLoading(false)
        }
      }
  
    useEffect(() => {
      fetchPresets()
    }, [])
  
    return {
      presets,
      loading,
      fetchPresets,
      savePreset,
      deletePreset
    }
  }