import { CircularProgress, Stack, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import campaignService from '../../service/CampaignService'
import { extractUserMessageFromError } from '../../util'
import ContentDataGrid from './ContentCheckData'

type Content = Awaited<ReturnType<typeof campaignService.getContentCheck>>[number]

export const AcceptContent = () => {
  const [contents, setContents] = useState<Content[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const loadContents = async () => {
    const key = enqueueSnackbar('Loading', { variant: 'info' })
    try {
      const contents = await campaignService.getContentCheck({})
      setContents(contents)
    } catch (e) {
      const userError = extractUserMessageFromError(e)
      enqueueSnackbar(userError || 'Fehler beim loadContents', { variant: 'error' })
      setError(true)
    } finally {
      setLoading(false)
    }
    closeSnackbar(key)
  }

  useEffect(() => {
    loadContents()
  }, [])

  if (loading) return <CircularProgress />
  if (error || !contents) return <Typography>Something went wrong</Typography>
  if (contents.length === 0) return <Typography>No content to accept</Typography>

  return (
    <Stack spacing={2}>
      {contents.map(c => (
        <ContentDataGrid key={c.influencerId} content={c} contents={contents} setContents={setContents} />
      ))}
    </Stack>
  )
}
