import { Add, History } from '@mui/icons-material'
import { Collapse, IconButton, List, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { PresetList } from './PresetList'
import { PresetSaveDialog } from './PresetSaveDialog'
import { usePresets } from './UsePresets'

interface ChatPresetsProps {
  onSelectPreset: (content: string) => void
  buttonSize?: 'small' | 'medium' | 'large'
}

export const ChatPresets: React.FC<ChatPresetsProps> = ({ onSelectPreset, buttonSize = 'medium' }) => {
  const { presets, loading, savePreset, deletePreset } = usePresets()
  const [anchorEl, setAnchorEl] = useState(false)
  const [showSaveDialog, setShowSaveDialog] = useState(false)

  const handleSwitchMenu = () => {
    setAnchorEl(!anchorEl)
  }

  const handleSavePreset = async (title: string, content: string) => {
    const success = await savePreset({
      content,
      title,
      presetId: ''
    })

    if (success) {
      setShowSaveDialog(false)
    }
  }

  const handleSelectPreset = (content: string) => {
    onSelectPreset(content)
    handleSwitchMenu()
  }

  const handleDeletePreset = async (presetId: string) => {
    await deletePreset(presetId)
  }

  return (
    <>
      <Tooltip title='Vorlage hinzufügen'>
        <IconButton onClick={() => setShowSaveDialog(true)} size={buttonSize} disabled={loading}>
          <Add />
        </IconButton>
      </Tooltip>
      <Tooltip title='Vorlagen laden'>
        <IconButton onClick={handleSwitchMenu} size={buttonSize} disabled={loading}>
          <History />
        </IconButton>
      </Tooltip>
      <Collapse in={anchorEl} timeout='auto' unmountOnExit sx={{ marginLeft: 1 }}>
        <List component='div' disablePadding>
          <PresetList presets={presets} loading={loading} onSelectPreset={handleSelectPreset} onDeletePreset={handleDeletePreset} />
        </List>
      </Collapse>
      <PresetSaveDialog open={showSaveDialog} loading={loading} onClose={() => setShowSaveDialog(false)} onSave={handleSavePreset} />
    </>
  )
}
